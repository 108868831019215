html,
body {
    height: 100%;
}

.footer {
    height: 60px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    padding-top: 20px;
}

.jumbotron {
    text-align: center;
    background-color: transparent;

    & .btn {
        font-size: 21px;
        padding: 14px 24px;
    }
}

.not-set {
    color: #c55;
    font-style: italic;
}

/* add sorting icons to gridview sort links */
a.asc:after, a.desc:after {
    content: '';
    left: 3px;
    display: inline-block;
    width: 0;
    height: 0;
    border: solid 5px transparent;
    margin: 4px 4px 2px 4px;
    background: transparent;
}

a.asc:after {
    border-bottom: solid 7px #212529;
    border-top-width: 0;
}

a.desc:after {
    border-top: solid 7px #212529;
    border-bottom-width: 0;
}

.grid-view th {
    white-space: nowrap;
}

.hint-block {
    display: block;
    margin-top: 5px;
    color: #999;
}
.hint-block .hint-popup {
    color: #333;
}

.error-summary {
    color: #a94442;
    background: #fdf7f7;
    border-left: 3px solid #eed3d7;
    padding: 10px 20px;
    margin: 0 0 15px 0;
}

/* 追加分 */
.required > label:after {
    color: #e00;
    content: "(必須)";
}

div.info {
    margin: 20px 10px 50px 10px;
}

.submit-button {
    margin-top: 50px;
}

dt.header {
    height:25px;
}

/* 自分の発言 */
div.panel-self {
    background-color: #ebf7fd;
    color: #2d7091;
}

/* 引用 */
blockquote {
    padding: 8px 15px;
    margin: 10px 0 10px;
    font-size: 100%;
    border-left: 5px solid #eee;
}

div.panel-self blockquote {
    padding: 8px 15px;
    margin: 10px 0 10px;
    font-size: 100%;
    border-left: 5px solid #dbe7ed;
}

div.content-body {
    padding-bottom: 10px;
}

.label-confirmation_url:after {
    color: #e00;
    content: " (下記で証拠のファイル添付でも可)";
}

.hint {
    padding: 0 0 1em 2em;
}
